
import punycode from 'punycode';
export class toolsHelper {
    domain = (name) => {
        const punycodeDomain = name
            ? punycode.toUnicode(name)
            : null;
        return punycodeDomain
    };
    domainToASCII = (name) => {
        return punycode.toASCII(name);
    }

    hexToRgba = (hex, opacity) => {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        const a = opacity; // прозрачность 50%
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    sleep = function (ms) {
        new Promise(resolve => setTimeout(resolve, ms))
    };
    /* The `debounce` function is a utility function that helps to limit the number of times a function
    is called within a certain time period. It takes two parameters: `func`, which is the function
    to be debounced, and `timeout`, which is the time period in milliseconds. */
    debounce = (func, timeout = 300) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    };
    /* The `debounce_leading` function is a variation of the `debounce` function that ensures the
    function `func` is called immediately on the first invocation, and then waits for the specified
    `timeout` before allowing subsequent invocations. */
    debounce_leading = (func, timeout = 300) => {
        let timer;
        return (...args) => {
            if (!timer) {
                func.apply(this, args);
            }
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = undefined;
            }, timeout);
        };
    }
}
export class formHelper {
    phoneMask = function () {
        return ([
            "+",
            "7",
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ]);
    }
}
import { bagtravelsApi } from "./classes/bagtravelsApi";
import { cmsApi } from "./classes/cmsApi";
// import { dbApi } from "./classes/dbApi";
// import { delfinApi } from "./classes/delfinApi";
import { emailApi } from "./classes/emailApi";
// import { libraryApi } from "./classes/libraryApi";
import { queryApi } from "./classes/queryApi";
export interface Api {
    bagtravels: bagtravelsApi
    cms: cmsApi

    // db: dbApi
    // delfin: delfinApi
    mail: emailApi
    // library: libraryApi
    query: queryApi
}

export const api: Api = {
    // alean: new aleanApi(),
    bagtravels: new bagtravelsApi(),
    cms: new cmsApi(),
    // db: new dbApi(),
    // delfin: new delfinApi(),
    mail: new emailApi(),
    // library: new libraryApi(),
    query: new queryApi()
};
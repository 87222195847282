import axios from 'axios';
import handleErrors from "../../../src/_helpers/handlerror";
import { helpers } from '../../_helpers/Helpers';


async function axiosPost(
    url: string, query?: any,
    tokenType: "cms" | "content" = "cms"
) {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + helpers.constant[tokenType].token
        };
        const response = await axios.post(url, { ...query }, { headers });

        return response;
    } catch (e) {
        console.log(`Error: ${e}`);
        return e;
    }
}

// export interface LandingApi {
//     collection: collectionApi;
// }
export class cmsApi {
    save = async function (name: string, data: any, host: "cms" | "content" = "cms") {
        const url = `${helpers.constant[host].host}/api/collections/save/${name}`;
        const query = {
            data: {
                ...data
            }
        };

        return await axiosPost(url, query, host)
            .then(handleErrors)
            .then(function (res) {
                return res.data;
            })
            .catch(function () {
                return null;
            });
    }
    get = async function (name, query = {}, single = false, host: "cms" | "content" = "cms", full = false) {
        const url = `${helpers.constant[host].host}/api/collections/get/${name}`;
        return await axiosPost(url, query, host)
            .then(function (res) {
                //return (value == null || value.length === 0 || value === undefined || value === false);
                if (full) {
                    return res.data;
                }

                if (res.data.entries.length === 0) {
                    if (single) {
                        return res.data[0];
                    } else {
                        return res.data;
                    }
                } else {
                    if (single) {
                        return res.data.entries[0];
                    } else {
                        return res.data.entries;
                    }
                }
            })
            .catch(function (e) {
                console.log("url", e);
                return false
            });
    };
    singleton = async function (singletonname = null) {
        if (!singletonname) return null;
        fetch(`/api/singletons/get/${singletonname}?token=xxtokenxx`)
            .then(data => data.json())
            .then(data => console.log(data));
    }
}
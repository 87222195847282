import axios from 'axios';
import { helpers } from '../../_helpers/Helpers';
import handleErrors from '../../../src/_helpers/handlerror';

export class emailApi {
    template = (templateType, variables, emailTemplate = null, header: string | null = "default", footer: string | null = "default") => {
        const headerHtml = header ? helpers.email.header(header) : '';
        const contentHtml = helpers.email.content(templateType, variables, emailTemplate);
        const footerHtml = footer ? helpers.email.footer(footer) : '';
        const template = `
            ${headerHtml}
            ${contentHtml}
            ${footerHtml}
        `;
        return template;
    };
    send = async (domain: string | null = null, data: any = null) => {
        const url = `https://${domain}/api/mail/send`;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            //'Authorization': 'Bearer ' + Conf.apiHostCms.Token
        };


        return await axios
            .post(url, { ...data }, { headers })
            .then(handleErrors)
            .then(function (res) {
                return res.data;
            })
            .catch(function () {
                return false
            });
    }
}
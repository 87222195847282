import { helpers } from "../Helpers";

export class imageHelper {
    getUrlImage = function (
        image: any,
        // true: storage/uploads; false: basePath
        storage = false,
        // content //cms
        cdn = false
    ) {
        // Проверяем, является ли объект пустым
        const isEmpty = (value: any) => {
            if (value === null || value === undefined) return true;
            if (typeof value === "string" && value.trim() === "") return true;
            if (Array.isArray(value) && value.length === 0) return true;
            if (typeof value === "object" && Object.keys(value).length === 0) return true;
            return false;
        };

        if (isEmpty(image)) return null;

        const domain = cdn ? helpers.constant.content.host : helpers.constant.cms.host;
        const path = storage ? "/storage/uploads" : "";
        const imageSrc = domain + path + image.path;

        const imgWithoutExtension = imageSrc.split(".").slice(0, -1).join(".");
        const imageWebPSrc = imgWithoutExtension + ".webp";

        return {
            original: imageWebPSrc,
            webP: imageWebPSrc,
        };
    };
}

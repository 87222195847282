import { constantHelper } from "./classes/constantClass";
import { dataHelper } from "./classes/dataClass";
import { dateHelper } from "./classes/dateClass";
import { emailHelper } from "./classes/emailClass";
import { formHelper } from "./classes/formClass";
import { imageHelper } from "./classes/imageClass";
import { routerHelper } from "./classes/routerClass";
import { toolsHelper } from "./classes/toolsClass";
export interface Helpers {
    constant: constantHelper,
    data: dataHelper,
    date: dateHelper,
    email: emailHelper,
    form: formHelper,
    image: imageHelper,
    router: routerHelper,
    tools: toolsHelper,
}

export const helpers: Helpers = {
    //landing: new landingApi()
    constant: new constantHelper(),
    data: new dataHelper(),
    date: new dateHelper(),
    email: new emailHelper(),
    form: new formHelper(),
    image: new imageHelper(),
    router: new routerHelper(),
    tools: new toolsHelper()
};

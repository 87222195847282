export class routerHelper {
    queryValue = (query: string | string[], type: "string" | "array" = "string", returnNullIfEmpty = false) => {

        if (query === undefined || query == null || query.length === 0) {
            return returnNullIfEmpty ? null : type === "string" ? "" : [];
        }
        if (type === "array") {
            return Array.isArray(query) ? query : [query];
        }
        return query;
    };
}
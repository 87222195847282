import invoice from "./emailTemplates/invoice";
import adminInvoice from "./emailTemplates/admin/invoice";


import adminHeader from "./emailTemplates/headers/admin";
import defaultHeader from "./emailTemplates/headers/default";

import adminFooter from "./emailTemplates/footers/admin";
import defaultFooter from "./emailTemplates/footers/default";


function generator(variables) {
    const template = variables.map((variable) => (
        `<p><strong>${variable.name}: </strong>${variable.value}</p>`
    ));
    return template.join('');
}
export class emailHelper {
    content = (type: string, variables: any, template?: any) => {
        switch (type) {
            case "invoice":
                return invoice(variables, template)
                break;
            case "adminInvoice":
                return adminInvoice(variables)
                break;

            // case "generator":
            //     return generator(variables)
            //     break;

            default:
                return null;
                break;
        }
    }
    footer = (type: string, variables?: any) => {
        switch (type) {
            case "admin":
                return adminFooter()
                break;
            case "default":
                return defaultFooter()
                break;
            default:
                return null;
                break;
        }
    }
    header = (type: string, variables?: any) => {
        switch (type) {
            case "admin":
                return adminHeader()
                break;
            case "default":
                return defaultHeader()
                break;
            default:
                return null;
                break;
        }
    }
    matchBooking
}
//@ts-nocheck
//NOTE - firstname, hotel, startDate, endDate, countLead,adults,kids,ages,priceName,room,priceCount
export default function (values, template) {
    const { firstName, room, checkIn, checkOut, night, adults, price, ages, typePrice } = values;
    let agesKids = ''
    if (ages) {
        let agesArr = ages.split(",");
        console.log("agesArr", agesArr);
        for (let i = 0; i < agesArr.length; i++) {
            agesKids += `<li><strong>Ребенок: ${i + 1}</strong> возраст ${agesArr[i]} </li>`;
        }
    }
    const html =
        `
        <p>Спасибо за Ваш выбор!</p>
        ${values.firstName ? `<p>Уважаемый(ая) ${values.firstName}</p>` : ''}
        ${template && template.header ? template.header : ''}
        <h1 style='margin-top: 50px;'>ДЕТАЛИ ЗАКАЗА:</h1>
        <ul>
        ${values.room ? `<li><strong>Номер: </strong> <span class="text">${values.room}</span ></li>` : ''}
        ${values.checkIn ? `<li><strong>Заезд: </strong> <span class="text">${values.checkIn}</span ></li>` : ''}
        ${values.checkOut ? `<li><strong>Выезд: </strong> <span class="text">${values.checkOut}</span ></li>` : ''}
        ${values.night ? `<li><strong>Ночей: </strong> <span class="text">${values.night}</span ></li>` : ''}
        ${values.adults ? `<li><strong>Взрослых: </strong> <span class="text">${values.adults}</span ></li>` : ''}
        ${agesKids ? agesKids : ''}
        ${values.typePrice ? `<li><strong>Тариф: </strong> <span class="text">${values.typePrice}</span ></li>` : ''}
        ${values.price ? `<li><strong>Стоимость: </strong> <span class="text">${values.price}</span ></li>` : ''}
       </ul>
        ${template && template.content ? template.content : ''}
        ${template && template.contacts ? template.contacts : ''}
        `;
    return html;
}
import axios from 'axios';
import { helpers } from '../../_helpers/Helpers';

async function axiosPost(url: string, query?: any, params?: any) {

}
// export interface LandingApi {
//     collection: collectionApi;
// }
export class queryApi {
    get = async function (url: string, query?: any, params: any = {}, returnNull = true) {
        try {
            const headers = {
                'Content-Type': 'application/json', // Пример заголовка
                // Другие заголовки...
            };

            const response = await axios.get(url, {
                params: query,
                headers: headers,
                ...params,
            });

            return response.data;
        } catch (error) {
            //return helpers.errors.requestErrors(error, returnNull = returnNull);
        }
    };
    post = async function (url: string, query?: any, params?: any) {
        try {
            let headers = { 'Content-Type': 'application/json' };
            if (params) {
                headers = params;
            }
            const response = await axios.post(url, { ...query }, { params });
            return response.data;
        } catch (error) {
            //return helpers.errors.requestErrors(error);
        }

        //Example FrontEnd
        // post('ваш_url', ваш_запрос, ваши_параметры)
        //     .then(response => {
        //         // Обработка успешного ответа
        //         console.log('Ответ:', response);
        //     })
        //     .catch(error => {
        //         // Обработка ошибки
        //         console.error('Ошибка:', error);
        //         // Дополнительная обработка ошибки в зависимости от её типа
        //         if (error.status === 401) {
        //             // Обработка ошибки авторизации
        //         } else {
        //             // Общая обработка остальных ошибок
        //         }
        //     });
    };
}
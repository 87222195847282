//@ts-nocheck
//NOTE admin message with page /booking
//NOTE - firstname, hotel, startDate, endDate, countLead,adults,kids,ages,priceName,room,priceCount
export default function (values) {
  let agesArr = null;
  if (values.ages) {
    agesArr = values.ages.split(",");
  }
  const html = `
      ${values.checkIn ? `<p><strong>Заезд: </strong>${values.checkIn}</p>` : ''}
      ${values.checkOut ? `<p><strong>Выезд: </strong>${values.checkOut}</p>` : ''}
      ${values.night ? `<p><strong>Ночей: </strong>${values.night}</p>` : ''}
      ${values.adults ? `<p><strong>Взрослых: </strong>${values.adults}</p>` : ''}
      ${agesArr ? `<p><strong>Детей: </strong>${agesArr.length}</p>` : ''}
      ${values.ages ? `<p><strong>Возраст: </strong>${values.ages}</p>` : ''}
      ${values.typePrice ? `<p><strong>Тип размещения: </strong>${values.typePrice}</p>` : ''}
      ${values.price ? `<p><strong>Стоимость: </strong>${values.price}</p>` : ''}
      ${values.room ? `<p><strong>Номер: </strong>${values.room}</p>` : ''}
      ${values.firstName ? `<p><strong>Имя: </strong>${values.firstName}</p>` : ''}
      ${values.lastName ? `<p><strong>Фамилия: </strong>${values.lastName}</p>` : ''}
      ${values.email ? `<p><strong>Email: </strong>${values.email}</p>` : ''}
      ${values.phone ? `<p><strong>Телефон: </strong>${values.phone}</p>` : ''}
      ${values.company ? `<p><strong>Компания: </strong>${values.company}</p>` : ''}
      ${values.message ? `<p><strong>Сообщение: </strong>${values.message}</p>` : ''}
      ${values.domain ? `<p><strong>Сайт: </strong>${values.domain}</p>` : ''}
  `;
  return html;
}
// interface IConstant {
//     bagtravels: string;
//     cms: {
//         host: string;
//         token: string;
//     },
//     content: {
//         host: string;
//         token: string;
//     },
//     domain: null | string | undefined;
//     formatDate: string;
//     imageFallback: string;
//     init: (modetype?: null | string, domainName?: null | string) => void;
//     invoice: number;
// }
// const _constantHelper: IConstant = {
//     bagtravels: "bagtravels.ru",
//     cms: {
//         host: "cms.bag-travels.ru",
//         token: "d0e5ac6519313ecd9373551d78cd8f",
//     },
//     content: {
//         host: "content.bag-travels.ru",
//         token: "919c36e005182c86df143eabe5b4d4",
//     },
//     domain: null,
//     formatDate: "DD.MM.YYYY",

//     imageFallback: `/dummy/600x400.png`,
//     init: (modetype = null, domainName = null) => {
//         const mode = modetype ? modetype : process.env.NODE_ENV;
//         _constantHelper.domain = domainName ? domainName : process.env.DOMAIN;
//     },
//     invoice: 7000,
// };
// export default _constantHelper;
interface IConstant {
    bagtravels: string;
    cms: {
        host: string;
        token: string;
    },
    content: {
        host: string;
        token: string;
    },
    domain: null | string | undefined;
    formatDate: string;
    imageFallback: string;
    init: (modetype?: null | string, domainName?: null | string) => void;
    invoices: number;
}
export class constantHelper implements IConstant {
    bagtravels: string;
    cms: {
        domain: string;
        host: string;
        token: string;
        storage: string;
    };
    content: {
        domain: string;
        host: string;
        token: string;
        storage: string;
    };
    domain: null | string | undefined;
    formatDate: string;
    imageFallback: string;
    invoices: number;

    constructor() {
        this.bagtravels = "bagtravels.ru";
        this.cms = {
            domain: "cms.bag-travels.ru",
            host: "https://cms.bag-travels.ru",
            storage: "https://cms.bag-travels.ru/storage/uploads",
            token: "d0e5ac6519313ecd9373551d78cd8f",
        };
        this.content = {
            domain: "content.bag-travels.ru",
            host: "https://content.bag-travels.ru",
            storage: "https://content.bag-travels.ru/storage/uploads",
            token: "919c36e005182c86df143eabe5b4d4",
        };
        this.domain = null;
        this.formatDate = "DD.MM.YYYY";
        this.imageFallback = `/dummy/600x400.png`;
        this.invoices = 7000;
        this.init();
    }

    init(modetype = null, domainName = null) {
        const mode = modetype ? modetype : process.env.NODE_ENV;
        this.domain = domainName ? domainName : process.env.DOMAIN;
    }
}

export default function () {
    const html =
        `<!DOCTYPE html>
        <html>
        <head>
        <meta charset="UTF-8">
        </head>
        <body style="margin: 0px;">
        `;
    return html;
}
import axios from 'axios';
import { helpers } from '../../_helpers/Helpers';

export class bagtravelsApi {
    search = async function (query: any) {
        helpers.constant.bagtravels
        const url = `https://${helpers.constant.bagtravels}/search-api/v2/search`;

        return await axios.post(url, { ...query })
            .then(function (res) {
                return res.data;
            })
            .catch(function () {
                return null;
            });
    }

    get = async function (method: string, query: any = {}) {
        const url = `https://${helpers.constant.bagtravels}/search-api/v2/${method}`;
        return await axios.get(url,
            {
                params: query,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (res) {
                return res.data;
            })
            .catch(function () {
                return null;
            });
    }

    post = async function (method: string, query: any = {}) {
        const url = `https://${helpers.constant.bagtravels}/search-api/v2/${method}`;
        return await axios.post(url, { ...query })
            .then(function (res) {
                return res.data;
            })
            .catch(function () {
                return null;
            });
    }
}
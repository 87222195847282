//@ts-nocheck
import { helpers } from "../Helpers";

export class dataHelper {
    animateClass = (animate) => {
        if (!animate || !animate.status) return '';
        return `animate__delay-${animate.delay} animate__animated animate__${animate.type} ${animate.speed} ${!animate.overflow && 'overflow-hidden position-relative'}`;
    }
    ignoreStyle = () => {
        return [
            "accent-color",
            "align-content",
            // "align-items",
            // "alignment-baseline",
            "anchor-name",
            "anchor-scope",
            "animation",
            "animation-composition",
            "animation-delay",
            "animation-direction",
            "animation-duration",
            "animation-fill-mode",
            "animation-iteration-count",
            "animation-play-state",
            "animation-range-end",
            "animation-range-start",
            "animation-timeline",
            "animation-timing-function",
            "app-region",
            "appearance",
            "backdrop-filter",
            "backface-visibility",
            "background-image",
            "baseline-shift",
            "baseline-source",
            "block-size",
            "border-block-end-color",
            "border-block-end-style",
            "border-block-end-width",
            "border-block-start-color",
            "border-block-start-style",
            "border-block-start-width",
            // "border-bottom-color",
            // "border-bottom-left-radius",
            // "border-bottom-right-radius",
            // "border-bottom-style",
            // "border-bottom-width",
            "border-collapse",
            "border-end-end-radius",
            "border-end-start-radius",
            // "border-left-color",
            // "border-left-style",
            // "border-left-width",
            // "border-right-color",
            // "border-right-style",
            // "border-right-width",
            "border-image-outset",
            "border-image-repeat",
            "border-image-slice",
            "border-image-width",
            "border-inline-end-color",
            "border-inline-end-width",
            "border-inline-start-color",
            "border-inline-start-width",
            "border-start-end-radius",
            "border-start-start-radius",
            // "border-top-color",
            // "border-top-left-radius",
            // "border-top-right-radius",
            // "border-top-style",
            // "border-top-width",
            // "bottom",
            "box-decoration-break",
            "box-sizing",
            "break-after",
            "break-before",
            "break-inside",
            "buffered-rendering",
            "caption-side",
            "caret-color",
            "clip",
            "clip-rule",
            // "color",
            "color-interpolation",
            "color-interpolation-filters",
            "color-rendering",
            // "column-count",
            // "column-gap",
            // "column-rule-color",
            // "column-rule-style",
            // "column-rule-width",
            // "column-span",
            // "column-width",
            "contain-intrinsic-block-size",
            "contain-intrinsic-height",
            "contain-intrinsic-inline-size",
            "contain-intrinsic-size",
            "contain-intrinsic-width",
            "container-name",
            "container-type",
            "content",
            "cx",
            "cy",
            "d",
            "direction",
            "dominant-baseline",
            "empty-cells",
            "fill",
            "fill-opacity",
            "fill-rule",
            "field-sizing",
            // "float",
            "flood-color",
            "flood-opacity",
            "font-optical-sizing",
            "font-palette",
            "font-synthesis-small-caps",
            "font-synthesis-style",
            "font-synthesis-weight",
            "font-variant-ligatures",
            "font-variant-caps",
            "font-variant-numeric",
            "font-variant-east-asian",
            "font-variant-alternates",
            "font-variant-position",
            "font-variant-emoji",
            // "height",
            "hyphenate-character",
            "hyphenate-limit-chars",
            "hyphens",
            "image-orientation",
            "image-rendering",
            "initial-letter",
            "inline-size",
            "inset-block-end",
            "inset-block-start",
            "inset-inline-end",
            "inset-inline-start",
            "interpolate-size",
            "isolation",
            "justify-items",
            "justify-self",
            // "left",
            "lighting-color",
            // "line-break",
            "list-style-image",
            "list-style-position",
            "list-style-type",
            "margin-block-end",
            "margin-block-start",
            // "margin-bottom",
            "margin-inline-end",
            "margin-inline-start",
            // "margin-left",
            // "margin-right",
            // "margin-top",
            "marker-end",
            "marker-mid",
            "marker-start",
            "mask-clip",
            "mask-composite",
            "mask-image",
            "mask-mode",
            "mask-origin",
            "mask-position",
            "mask-repeat",
            "mask-size",
            "mask-type",
            "math-depth",
            "math-shift",
            "math-style",
            "max-block-size",
            "max-inline-size",
            "min-block-size",
            "min-inline-size",
            "mix-blend-mode",
            // "object-fit",
            "object-position",
            "object-view-box",
            "offset-anchor",
            "offset-distance",
            "offset-path",
            "offset-position",
            "offset-rotate",
            "orphans",
            "outline-color",
            "outline-offset",
            "outline-style",
            "outline-width",
            "overflow-anchor",
            "overflow-clip-margin",
            "overflow-wrap",
            "overflow-x",
            "overflow-y",
            "overlay",
            "overscroll-behavior-block",
            "overscroll-behavior-inline",
            "padding-block-end",
            "padding-block-start",
            "padding-inline-end",
            "padding-inline-start",
            "paint-order",
            "perspective",
            "perspective-origin",
            "pointer-events",
            "position-anchor",
            "position-try-order",
            "position-visibility",
            // "position",
            "r",
            "resize",
            "right",
            // "rotate",
            // "row-gap",
            "ruby-align",
            "ruby-position",
            "rx",
            "ry",
            // "scale",
            "scroll-behavior",
            "scroll-margin-block-end",
            "scroll-margin-block-start",
            "scroll-margin-inline-end",
            "scroll-margin-inline-start",
            "scroll-padding-block-end",
            "scroll-padding-block-start",
            "scroll-padding-inline-end",
            "scroll-padding-inline-start",
            "scroll-timeline-axis",
            "scroll-timeline-name",
            "scrollbar-color",
            "scrollbar-gutter",
            "scrollbar-width",
            "shape-image-threshold",
            "shape-margin",
            "shape-outside",
            "shape-rendering",
            "speak",
            "stop-color",
            "stop-opacity",
            "stroke",
            "stroke-dasharray",
            "stroke-dashoffset",
            "stroke-linecap",
            "stroke-linejoin",
            "stroke-miterlimit",
            "stroke-opacity",
            "stroke-width",
            "tab-size",
            "table-layout",
            "text-align-last",
            "text-anchor",
            "text-decoration-color",
            "text-decoration-skip-ink",
            "text-decoration-style",
            "text-decoration-thickness",
            "text-emphasis-color",
            "text-emphasis-position",
            "text-emphasis-style",
            "text-indent",
            "text-overflow",
            "text-rendering",
            "text-size-adjust",
            "text-spacing-trim",
            "text-underline-position",
            "text-wrap-mode",
            "text-wrap-style",
            "timeline-scope",
            "top",
            "touch-action",
            "transform",
            "transform-origin",
            "transition",
            "transition-delay",
            "transition-duration",
            "transition-property",
            "transition-timing-function",
            "translate",
            "unicode-bidi",
            "user-select",
            "vector-effect",
            "view-timeline-axis",
            "view-timeline-inset",
            "view-timeline-name",
            "view-transition-class",
            "view-transition-name",
            // "visibility",
            "white-space-collapse",
            "widows",
            // "width",
            "will-change",
            "writing-mode",
            "x",
            "y",
            // "z-index",
            "zoom",
            "-webkit-border-horizontal-spacing",
            "-webkit-border-image",
            "-webkit-border-vertical-spacing",
            "-webkit-box-align",
            "-webkit-box-decoration-break",
            "-webkit-box-direction",
            "-webkit-box-flex",
            "-webkit-box-ordinal-group",
            "-webkit-box-orient",
            "-webkit-box-pack",
            "-webkit-box-reflect",
            "-webkit-font-smoothing",
            "-webkit-line-break",
            "-webkit-line-clamp",
            "-webkit-locale",
            "-webkit-mask-box-image",
            "-webkit-mask-box-image-outset",
            "-webkit-mask-box-image-repeat",
            "-webkit-mask-box-image-slice",
            "-webkit-mask-box-image-source",
            "-webkit-mask-box-image-width",
            "-webkit-print-color-adjust",
            "-webkit-rtl-ordering",
            "-webkit-tap-highlight-color",
            "-webkit-text-combine",
            "-webkit-text-decorations-in-effect",
            "-webkit-text-fill-color",
            "-webkit-text-orientation",
            "-webkit-text-security",
            "-webkit-text-stroke-color",
            "-webkit-text-stroke-width",
            "-webkit-user-drag",
            "-webkit-user-modify",
            "-webkit-writing-mode",
        ];
    };
    getTypePage = (slug) => {
        let type = null;
        switch (slug) {
            case 'rooms':
                type = 'rooms';
                break;
            case 'services':
                type = 'services';
                break;
            case 'therapy':
                type = 'therapy';
                break;
        }
        return type;
    };
    defaultCarouselProps = () => {
        return ({
            additionalTransfrom: 0,
            arrows: true,
            autoPlaySpeed: 12000,
            centerMode: false,
            className: "",
            containerClass: "container-with-dots py-3",
            dotListClass: "",
            draggable: true,
            focusOnSelect: false,
            infinite: true,
            itemClass: "",
            keyBoardControl: true,
            minimumTouchDrag: 80,
            renderButtonGroupOutside: false,
            renderDotsOutside: false,
            responsive: {
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 1,
                    partialVisibilityGutter: 40
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                }
            },
            showDots: false,
            sliderClass: "",
            slidesToSlide: 1,
            swipeable: true
        })
    };
    agesList = () => {
        return ([
            { value: "0", label: "до года" },
            { value: "1", label: "1 год" },
            { value: "2", label: "2 года" },
            { value: "3", label: "3 года" },
            { value: "4", label: "4 года" },
            { value: "5", label: "5 лет" },
            { value: "6", label: "6 лет" },
            { value: "7", label: "7 лет" },
            { value: "8", label: "8 лет" },
            { value: "9", label: "9 лет" },
            { value: "10", label: "10 лет" },
            { value: "11", label: "11 лет" },
            { value: "12", label: "12 лет" },
            { value: "13", label: "13 лет" },
            { value: "14", label: "14 лет" },
            { value: "15", label: "15 лет" },
            { value: "16", label: "16 лет" },
            { value: "17", label: "17 лет" },
        ])
    };
    localeDatePicker = () => {
        return ({
            format: helpers.constant.formatDate,
            separator: " - ",
            applyLabel: "Применить",
            cancelLabel: "Отменить",
            fromLabel: "Заезд",
            toLabel: "Выезд",
            //"customRangeLabel": "Custom",
            weekLabel: "Неделя",
            daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            monthNames: [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
            ],
            firstDay: 1,
        })
    }
}